import React from 'react'
import '../styles/productDetails.css'
import Helmet from 'react-helmet'

import img2 from '../images/Rect8.png'
import logo from '../images/headerLogo.png'
import Layout from '../components/layout'
import square from '../images/square.png'

import rectItems1 from '../images/superMarket/Rectangle 34.png'
import rectItems2 from '../images/superMarket/Rectangle 31.png'

import product1 from '../images/superMarket/image1.png'
import product2 from '../images/superMarket/image 12.png'
import product3 from '../images/superMarket/image 13.png'
import product4 from '../images/superMarket/image 66.png'
import product5 from '../images/superMarket/image 67.png'
import product6 from '../images/superMarket/image 57.png'
import product7 from '../images/superMarket/image 68.png'
import product8 from '../images/superMarket/image 9.png'
import Contact from '../components/contact'




const Product1 = () => {

  return (

    
    <div className=' font-hind'>
      <Layout>
        
        <Helmet title="Supermarket Refrigeration Systems | Better Freezing Solutions"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Complete solution for refrigeration systems.We provide supermarket refrigeration,cold storage refrigeration,kitchen refrigeration medical refrigeration,freezers,coolers.',
                    }
                ]}
        />
        
        <div className='productDetailSection'>
          
          <div className='flex  relative'>
            <img className='img2 absolute right-0' src={img2} alt='' />

            <div className='PDItemsSection absolute right-0 z-30 flex sm:justify-end justify-center'>
              <div className='flex items-end'>
                <img className='PDImage1 absolute z-10' src={rectItems1} alt='item' />
                <img className='PDImage2' src={rectItems2} alt='item' />
              </div>
            </div>
          </div>

          
          
          <div className='productTitleSection flex justify-center text-white'>
            <div className='z-30'>
              
              <div className='flex justify-center items-center  sm:items-end sm:justify-start px-6'>
                <img className='logo ' src={logo} alt='' />
                <h1 className='productTitle hidden sm:flex text-white'>Supermarket Refrigeration</h1>
                <h1 className='productTitle block sm:hidden text-white'>Supermarket<br/> Refrigeration</h1>
              </div>
              
              <div className=''>
                <div className='PDListGrid grid lg:grid-cols-3  sm:grid-cols-2 grid-cols-1 xl:gap-8 xl:gap-y-2 lg:gap-5 lg:gap-y-2  md:gap-0 md:gap-y-1'>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /><h1 className='PDListText'>Multi Deck Chiller</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /><h1 className='PDListText'>Open Chiller</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /><h1 className='PDListText'>Pastry Showcase</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /><h1 className='PDListText'>Serve Over Counter</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /><h1 className='PDListText'>Island Chiller</h1> </div>
                </div>
              </div>

              
            </div>
          </div>

        </div>

        <div className='flex justify-center'>
          <h1 className='PDText'>
            Now offering new cutting-edge range of models in supermarket refrigeration. These new  models are 
            sustainable, practical, elegant and consumes less power due to their smart design.
            <br/>
            <br/>
            Now offering new cutting-edge range of models in supermarket refrigeration. These new  models are 
            sustainable, practical, elegant and consumes less power due to their smart design.The range of Open Deck 
            Chillers & Freezers is designed aesthetically as well as with the use of path-breaking technology for 
            strong operations, preserving the freshness of foods, while maximizing display area for products stored. 
              
          </h1>
        </div>

        <div className='flex justify-center'>
          <div className='PDProductGrid grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  2xl:gap-10 gap-3 gap-y-5 pb-4'>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product1} alt='p1' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product4} alt='p1' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product5} alt='p1' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product8} alt='p1' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product3} alt='p1' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product7} alt='p1' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product6} alt='p1' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product2} alt='p1' /></div>
          </div>
        </div>

        <Contact/>
         
      </Layout>
    </div>
  )
}

export default Product1
